﻿// 1. Colors
$black: #202324;
$white: #fff;
$secondary-white: #f2f3f4;
$lighter-gray: #dfe1e4;
$light-gray: #ccc;
$gray: #a6a6a6;
$theme: #000000;
$green: #00cc66;
$red: #f64e60;
$orange: #ff964f;
$blue: #0099ff;
$pink: #ff6699;
$yellow: #ffd050;
$faded-purple: #e88ee5;
// 2. Fonts
$font: "Poppins", sans serif;
$font-bold: "Poppins", sans serif;
$font-awesome: "FontAwesome5Free";
$input-font-size: 16px;
$breakpoint-l: 425px;
