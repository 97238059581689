#loginPage,
#registerPage {
    .carousel-inner {
        border-radius: 0 10px 10px 0;
    }
    .carousel-caption {
        text-align: left;
        left: 5%;
    }
    .btn-login {
        flex: 1;
        background: $red;
        color: $white;
        font-weight: 600;
        margin-left: 7%;
    }
    .banner-text {
        width: 70%;
        position: absolute;
        bottom: 40px;
        padding-left: 20px;
    }
    .banner-text h2 {
        color: $white;
        font-weight: 600;
    }
    .banner-text h2:after {
        content: " ";
        width: 100px;
        height: 5px;
        background: $white;
        display: block;
        margin-top: 20px;
        border-radius: 3px;
    }
    .banner-text p {
        color: $white;
    }
    @media (min-width: 576px) {
        .jumbotron {
            padding: 10px;
        }
    }
    .login-page {
        margin: auto auto;
        padding: 50px 60px 50px 60px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin-top: -50px !important;
        .login-block {
            background: $red;
            /* fallback for old browsers */
            background: -webkit-linear-gradient(to bottom, #ffb88c, $red);
            /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient( to bottom, #ffb88c, $red);
            /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            float: left;
            width: 100%;
            padding: 50px 0;
        }
        .login-sec {
            padding: 50px 30px;
            position: relative;
            .copy-text {
                position: absolute;
                width: 80%;
                bottom: 20px;
                font-size: 13px;
                text-align: center;
            }
            .copy-text i {
                color: #feb58a;
            }
            .copy-text a {
                color: $red;
            }
            h2 {
                margin-bottom: 30px;
                font-weight: 800;
                font-size: 30px;
                color: $red;
            }
            h2:after {
                content: " ";
                width: 100px;
                height: 5px;
                background: #feb58a;
                display: block;
                margin-top: 20px;
                border-radius: 3px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .container {
            background: $white;
            border-radius: 10px;
            box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
        }
        .login-form-section {
            padding: 20px;
            height: 100%;
            border-radius: 5px 0px 0px 5px;
            background: $white;
        }
        .login-btn-div {
            display: flex;
            justify-content: center;
            button {
                width: 80%;
            }
        }
    }
    .bg-image-register {
        width: 100vw !important;
        height: 100vh !important;
        background-image: url("../../assets/img/accounting.jpg");
        position: absolute;
        filter: blur(10px);
    }
    .bg-image-login {
        width: 100vw !important;
        height: 100vh !important;
        background-image: url("../../assets/img/accounting_1.jpg");
        position: absolute;
        filter: blur(4px) grayscale(85%);
    }
    .account-register-page {
        margin: auto auto;
        padding: 50px 60px 50px 60px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100vh;
        margin-top: -50px !important;
        .container {
            width: 45%;
            background: $white;
            border-radius: 10px;
            box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
        }
        .react-tel-input {
            input {
                width: 100%;
                height: 43px;
            }
        }
        .login-form-section {
            padding: 20px;
            min-height: 100%;
            border-radius: 5px;
            background: $white;
            ol: {
                display: flex;
                justify-content: center;
                padding-left: 0px;
            }
        }
        .login-sec {
            padding: 50px 30px;
            position: relative;
            .copy-text {
                position: absolute;
                width: 80%;
                bottom: 20px;
                font-size: 13px;
                text-align: center;
            }
            .copy-text i {
                color: #feb58a;
            }
            .copy-text a {
                color: $red;
            }
            h2 {
                margin-bottom: 30px;
                font-weight: 800;
                font-size: 30px;
                color: $red;
            }
            h2:after {
                content: " ";
                width: 100px;
                height: 5px;
                background: #feb58a;
                display: block;
                margin-top: 20px;
                border-radius: 3px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .sign-up-btn {
            border-radius: 3px;
            margin-top: 20px;
            width: 70%;
            background-color: $red;
            border: none;
        }
        .registrationInnerForm {
            min-height: 400px;
            margin: 0px 80px 0px 80px;
            .fileUploader {
                border: 1px dashed grey;
            }
            .email-verification-btn {
                background-color: $red;
                border-radius: 3px;
                border: none;
            }
        }
    }
    .LoginTacPanel {
        .login-tac-body {
            padding-left: 35px;
            padding-right: 35px;
        }
        .circle {
            display: inline-block;
            border-radius: 60px;
            box-shadow: 0px 0px 2px $black;
            padding: 0.5em 0.6em;
            cursor: pointer;
            background: $gray;
        }
    }
    .login-tac-foot {
        display: flex;
        justify-content: space-evenly;
        @include h-padding(12%);
    }
    .footer-buttons {
        margin: 20px auto 20px auto;
        display: flow-root;
        width: 80%;
        .btn:focus,
        .btn:active,
        .btn {
            padding: 0px;
            background: transparent;
            border: none;
            box-shadow: none;
            & svg :hover {
                color: $black !important;
            }
        }
        .btn-icons {
            width: 35px;
            height: 35px;
            color: $gray;
        }
    }
    .TacVerification {
        .otp-input-container {
            input {
                width: 1.3em !important;
            }
        }
    }
    .modal {
        display: flex !important;
        .ForgotPasswordModal {
            margin: auto;
            .reset-pw-email-btn {
                border-radius: 2px;
                background: $black;
                color: $white;
            }
        }
    }
}

.card-login {
    max-width: 500px;
    margin: 0 auto;
    >.card-body {
        padding: 3rem;
    }
}

.card-login,
.card-register {
    .title {
        margin-bottom: 1rem
    }
}

.card-register {
    max-width: 550px;
    margin: 0 auto;
    >.card-body {
        padding: 3rem;
    }
    ol.progtrckr {
        li {
            line-height: 1;
            margin-top: 1rem;
        }
    }
}

.otp-input-container {
    >div {
        &:first-child {
            input {
                border-radius: 3px 0 0 3px;
            }
        }
        &:last-child {
            input {
                border-radius: 0 3px 3px 0;
                border-left: none;
            }
        }
        &:not(:last-child):not(:first-child) {
            input {
                border-left: none;
            }
        }
        input {
            width: 2rem !important;
            height: 3rem;
            border: 1px solid #ccc;
            &:focus {
                outline: none;
            }
        }
    }
}

.login-container {
    height: 100%;
    background-image: url("../../assets/img/login-bg/bglogin.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    position: relative;

    .content-container {
        position: absolute;
        top: 10%;
        left: 27.5%;
        min-width: 280px;
        width: 280px;
        border-radius: 15px;
        padding: 20px;
    }

    .title-row {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .title {
            font-size: 6rem;

            &.black {
                color: black;
            }

            &.white {
                color: #f2f2f2;
            }
        }
    }
}